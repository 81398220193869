import './layouts.less'
import Asides from './Asides'
import Personal from './Personal'
import NavigationBar from './NavigationBar'
import NavigationTitle from './NavigationTitle'
import { AuthenComp } from '@/components/AuthenComp'

import { Outlet } from 'react-router-dom'
import { Col, Layout, Row } from 'antd';
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useStore } from '@/store'
import Personal2 from './Personal2'
const { Header, Footer, Sider, Content } = Layout;


const Layouts = ({ children }) => {
  const { navigationStore: NS, globalStore: GS } = useStore()
  useEffect(() => {
    NS.refreshView(true)
    return () => {
      NS.refreshView()
    }
  }, [])
  return (
    <Layout>
      <Sider>
        <div className='asideBox'>
          <div className='asideLogo'>
            <img src={require('@/assets/images/logo.png')} alt="logo" style={{ 'width': '120px', 'height': '34px' }} />
          </div>
          <div className='asideContent'>
            <Asides></Asides>
          </div>
          {/* <div className='asidePersonal'>
            <Personal></Personal>
          </div> */}
        </div>

      </Sider>
      <Layout>
        <Header>
          <Row>
            <Col flex="auto"><NavigationBar /></Col>
            <Col flex="420px" className='perl'><Personal2 /></Col>
          </Row>
          <NavigationTitle />
        </Header>
        <Content>
          <div className={`${GS.globalData.style === 1 ? 'mainBox' : 'mainBox2'}`}>
            {/* {children} */}
            <AuthenComp>
              <Outlet />
            </AuthenComp>
          </div>
        </Content>
        {/* <Footer>Footer</Footer> */}
      </Layout>
    </Layout>
  );
}

export default observer(Layouts);