import { message } from "antd";
import { makeAutoObservable } from "mobx";

class NavigationStore {
  navigateList = [
    {
      title: '赛事列表',
      path: 'competition/listOfEvents'
    },
  ]
  navigateChildrenList = []
  navName = ''

  constructor() {
    makeAutoObservable(this)
  }

  setNavName = (name) => {
    this.navName = name
  }

  // 添加导航
  addList = (val) => {
    const idx = this.navigateList.findIndex(i => i.path === val.path)
    idx < 0 && this.navigateList.push(val)
  }
  // 删除导航
  delList = (val) => {
    const idx = this.navigateList.findIndex(i => i.path === val.path)
    // console.log('val',idx)
    this.navigateList.splice(idx, 1)
  }
  // 刷新页面时把导航存到session里
  refreshView = (val) => {
    if (val) {
      // console.log('取数据',)
      this.navigateList = sessionStorage.getItem('navigateList') ? JSON.parse(sessionStorage.getItem('navigateList')) : this.navigateList
      // this.navigateList =  JSON.parse(sessionStorage.getItem('navigateList'))
      this.navigateChildrenList = sessionStorage.getItem('navigateChildrenList') ? JSON.parse(sessionStorage.getItem('navigateChildrenList')) : this.navigateChildrenList
      return
    }
    // console.log('存数据',)
    sessionStorage.setItem('navigateList', JSON.stringify(this.navigateList))
    sessionStorage.setItem('navigateChildrenList', JSON.stringify(this.navigateChildrenList))
  }
  // 添加导航二级标题
  addNavigateChildrenList = (val) => {
    const idx = this.navigateChildrenList.findIndex(i => i === val)
    idx < 0 && this.navigateChildrenList.push(val)

  }
  delNavigateChildrenList = (all) => {
    if (all) {
      this.navigateChildrenList = []
      return
    }
    this.navigateChildrenList && this.navigateChildrenList.pop()
  }

}
export { NavigationStore }