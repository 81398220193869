import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Space, Badge, Avatar, Form, Popover, Button, Radio, Modal, Input, message } from "antd";
import { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { $api } from '@/config/api'

import { useStore } from '@/store'
import { useNavigate } from "react-router-dom";
import { FnImg } from "movm";
import FileUpload from '../pages/Competition/FileUpload';


const Personal2 = () => {
  const icon = require('@/assets/images/asideIcon/notification@2x.png')
  const userPhoto = require('@/assets/images/personal/userPhoto.png')
  const personalIcon = require('@/assets/images/icons/user-circle.png')
  const [time, setTime] = useState()
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const navigate = useNavigate()
  const [info, setInfo] = useState({})
  const { navigationStore: NS } = useStore()
  const [visible, setVisible] = useState(false)

  const [imgUrl, setImgUrl] = useState(null)
  const [imgList, setImgList] = useState([])
  const [showPicModal, setShowPicModal] = useState(false)
  const [showPramas, setShowPramas] = useState({})
  function showConfirm() {
    confirm({
      title: '确定要退出吗 ?',
      icon: <ExclamationCircleOutlined />,
      content: '你将要退出当前账号',
      okText: '确定退出',
      cancelText: '取消',
      onOk() {
        console.log('确定退出!');
        setTimeout(() => {
          navigate('/login')
          window.localStorage.removeItem('token')
          NS.navigateList = []
          window.localStorage.removeItem('path')
          sessionStorage.setItem('navigateList', JSON.stringify(NS.navigateList))
        }, 100);
      },
      onCancel() {
        console.log('取消');
      },
    });
  }

  function showConfig() {
    confirm({
      title: '确定要清除系统缓存吗 ?',
      icon: <ExclamationCircleOutlined />,
      // content: '你将要退出当前账号',
      okText: '确定清除',
      cancelText: '取消',
      onOk() {
        $api.clearConfig().then(res => {
          if (res.code == 200) {
            message.success(res.message)
          }
        })
      },
      onCancel() {
        console.log('取消');
      },
    });
  }


  useEffect(function () {
    setTime(localStorage.getItem('loginTime') ? localStorage.getItem('loginTime') : '0000-00-00')
    let obj = {
      nickName: localStorage.getItem('nickName'),
      identity: localStorage.getItem('identity'),
      id: localStorage.getItem('uid'),
      phone: localStorage.getItem('phone'),
    }
    if (localStorage.getItem('photo') && localStorage.getItem('photo') != 'null') {
      obj.photo = localStorage.getItem('photo')
    }
    setInfo(obj)

  }, [])

  const handleEditInfo = (e) => {
    form.setFieldsValue(e)
    console.log('info:', e, form.getFieldsValue())
    setVisible(true)
  }
  const handleEdit = () => {
    console.log('info:', form.getFieldsValue())
    form.validateFields().then(res => {
      console.log(res, imgUrl)
      if (res.password === res.newPassword) {
        console.log(res, imgUrl)
        // $api.setInfo(res.gender, res.id, res.nickName, res.password, imgUrl).then(res => {
        $api.setInfo(
          {
            ...res
          }
        ).then(res => {
          message.success(res.message)
          setVisible(false)
          form.resetFields()
          setImgUrl(null)
          setImgList([])
        })

      } else {
        message.warn('两次密码不一致！')

      }
    })
  }
  const initialValues = {
    // gender: 0
  }


  const content = (
    <>
      <div className="userInfoPanel2" onClick={() => {
        handleEditInfo(info)
      }}>
        <img src={userPhoto} alt="" className='userPhoto' />
        <div className='userInfoBox'>
          <p>{info.nickName}</p>
          <p>{info.identity}</p>
        </div>
      </div>
      <div className="exitClear" onClick={showConfig}>
        清除系统缓存
      </div>
      <div className="exitLogin" onClick={showConfirm}>
        退出登录
      </div>
    </>
  )
  return (
    <>
      <Space size={16}>
        <Space className="rigicon">&nbsp;</Space>
        <Space>上次登录时间: {time}</Space>
        {/* <Space>
          <a href="#">
            <Badge count={5}>
              <img src={icon} alt="" style={{ width: '16px', height: '16px' }} />
            </Badge>
          </a>
        </Space> */}
        <Popover content={content} placement="bottomRight">
          <Space className="userInfoPanel2Hover">
            <img src={personalIcon} alt="" style={{ width: '16px', height: '16px', 'marginBottom': '4px' }} /> {info.nickName}
            <DownOutlined style={{ fontSize: '10px', height: '14px', cursor: 'pointer' }} />
          </Space>
        </Popover>
      </Space>
      <Modal title="修改个人信息"
        visible={visible}
        okText='修改'
        onOk={() => {
          handleEdit()
        }}
        onCancel={() => {
          setVisible(false)
          form.resetFields()
          setImgList([])
          setImgUrl()
        }}>
        {/* 素材中心弹窗 */}
        {showPicModal &&
          <FileUpload params={showPramas} setShowPicModal={() => { setShowPicModal() }}
            atChange={(e) => {
              const [pic] = e
              if (showPramas.file == 'image') {
                setImgUrl(pic.reqUrl)
              }
              setShowPicModal(false)
            }}
          ></FileUpload>
        }
        <Form
          initialValues={initialValues}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          form={form}
        >
          <Form.Item
            label="id"
            name="id"
            hidden
          ></Form.Item>
          {/* <Form.Item
            label="头像"
            name="photo"
            required
          >
            {imgUrl ? <FnImg data={imgUrl} ih={200} iw={200} atChange={(e) => {
              console.log('返回的图片集合', e)
              setImgList([...e])
            }} /> : ''}
            {
              !imgUrl && <Button icon={<UploadOutlined />}
                onClick={() => {
                  setShowPramas({ file: 'image', width: 200, height: 200 })
                  setShowPicModal(true)
                }}>点击上传</Button>
            }
          </Form.Item> */}
          <Form.Item
            label="昵称"
            name="nickName"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入内容" />
          </Form.Item>
          {/* <Form.Item
            label="性别"
            name="gender"
            required
          >
            <Radio.Group>
              <Radio value={0}>未知</Radio>
              <Radio value={1}>男</Radio>
              <Radio value={2}>女</Radio>
            </Radio.Group>
          </Form.Item> */}
          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true }]}
          >
            <Input.Password placeholder="请输入内容" style={{ borderColor: '#b4b1b1' }} />
          </Form.Item>
          <Form.Item
            label="确认密码"
            name="newPassword"
            rules={[{ required: true }]}
          >
            <Input.Password placeholder="请输入内容" style={{ borderColor: '#b4b1b1' }} />
          </Form.Item>
        </Form>

      </Modal>
    </>
  );
}

export default Personal2;