
// // const onRouteBefore = ({ pathname, meta }) => {
// //   // 动态修改页面title
// //   if (meta.title !== undefined) {
// //     document.title = meta.title
// //   }
// //   // 判断未登录跳转登录页
// //   // if (meta.needLogin) {
// //   //   if (!isLogin) {
// //   //     return '/login'
// //   //   }
// //   // }
// // }

// export default routes
import Layouts from '../layout'
import Login from '@/pages/Login/Login'
import { lazy, Suspense } from 'react'
import { Spin } from 'antd'

const DataOverview = lazy(() => import('@/pages/OverView/DataOverview'))
const EventStatistics = lazy(() => import('@/pages/OverView/EventStatistics'))
const OrdersReceivedStatistics = lazy(() => import('@/pages/OverView/OrdersReceivedStatistics'))
const About = lazy(() => import('@/pages/About/About'))
// const Login = lazy(() => import('@/pages/Login/Login'))
const Notfond = lazy(() => import('@/pages/Error/NotFond'))
// 赞助商
const JcontentManagement = lazy(() => import('@/pages/System/ContentManagement'))

const PublishEvent = lazy(() => import('@/pages/Competition/PublishEvent'))
const ListOfEvents = lazy(() => import('@/pages/Competition/ListOfEvents'))
const RegistrationManagement = lazy(() => import('@/pages/Competition/RegistrationManagement'))
const OrderDetail = lazy(() => import('@/pages/Competition/OrderDetail'))
const PerformanceManagement = lazy(() => import('@/pages/Competition/PerformanceManagement'))
const PerformanceManagementEdit = lazy(() => import('@/pages/Competition/PerformanceManagementEdit'))
const ApplicantInformationManagement = lazy(() => import('@/pages/Competition/ApplicantInformationManagement'))
const EventService = lazy(() => import('@/pages/Competition/EventService'))
const TermsNotice = lazy(() => import('@/pages/Competition/TermsNotice'))
const TermsAgreement = lazy(() => import('@/pages/Competition/TermsAgreement'))
const BlacklistManagement = lazy(() => import('@/pages/Competition/BlacklistManagement'))
const TaglistManagement = lazy(() => import('@/pages/Competition/TaglistManagement'))
const InvitationCodeManagement = lazy(() => import('@/pages/Competition/InvitationCodeManagement'))
const AccountManagement = lazy(() => import('@/pages/UserControl/AccountManagement'))
const CenterManager = lazy(() => import('@/pages/UserControl/CenterManager'))
const CancellationApplication = lazy(() => import('@/pages/UserControl/CancellationApplication'))
const AccountDetails = lazy(() => import('@/pages/UserControl/AccountDetails'))
const CMAccountDetails = lazy(() => import('@/pages/UserControl/CMAccountDetails'))
const SortManagement = lazy(() => import('@/pages/Journalism/SortManagement'))
const ContentManagement = lazy(() => import('@/pages/Journalism/ContentManagement'))
const PublishContent = lazy(() => import('@/pages/Journalism/PublishContent'))
const RegistrationReview = lazy(() => import('@/pages/Audit/RegistrationReview'))
const ApplicantInformationDetails = lazy(() => import('@/pages/Audit/ApplicantInformationDetails'))
const MiddlegroundEventAudit = lazy(() => import('@/pages/Audit/MiddlegroundEventAudit'))
const DetailsOfMidStageEvents = lazy(() => import('@/pages/Audit/DetailsOfMidStageEvents'))
const CommentReview = lazy(() => import('@/pages/Audit/CommentReview'))
const RefundAudit = lazy(() => import('@/pages/Audit/RefundAudit'))
const DetailsRefundAudit = lazy(() => import('@/pages/Audit/DetailsRefundAudit'))
const PostersManagement = lazy(() => import('@/pages/System/PostersManagement'))
const EditThePoster = lazy(() => import('@/pages/System/EditThePoster'))
const PushTheManagement = lazy(() => import('@/pages/System/PushTheManagement'))
const Feedback = lazy(() => import('@/pages/System/Feedback'))
const ReportManagement = lazy(() => import('@/pages/System/ReportManagement'))
const MaterialCenter = lazy(() => import('@/pages/System/MaterialCenter'))
const Broadcast = lazy(() => import('@/pages/System/Broadcast'))
const Advertising = lazy(() => import('@/pages/System/Advertising'))
const EditAdvertising = lazy(() => import('@/pages/System/EditAdvertising'))
const EditQa = lazy(() => import('@/pages/System/EditQa'))
const Wallet = lazy(() => import('@/pages/System/Wallet'))
const WalletRunning = lazy(() => import('@/pages/System/WalletRunning'))
const WalletDetail = lazy(() => import('@/pages/System/WalletDetail'))
const Order = lazy(() => import('@/pages/System/Order'))
const Gold = lazy(() => import('@/pages/System/Gold'))
const CloseGold = lazy(() => import('@/pages/System/CloseGold'))
const DetailGold = lazy(() => import('@/pages/System/DetailGold'))
const Photo = lazy(() => import('@/pages/System/Photo'))
const Upload = lazy(() => import('@/pages/System/Upload'))

const Setting = lazy(() => import('@/pages/Set/Settings'))


const MenuManagement = lazy(() => import('@/pages/Jurisdiction/MenuManagement'))
const BackAccount = lazy(() => import('@/pages/Jurisdiction/BackAccount'))
const LogManagement = lazy(() => import('@/pages/System/LogManagement'))
const DictionaryManagement = lazy(() => import('@/pages/System/DictionaryManagement'))
const SettlementRecords = lazy(() => import('@/pages/AccountChecking/SettlementRecords'))
const BillingDetails = lazy(() => import('@/pages/AccountChecking/BillingDetails'))



const EditSession = lazy(() => import('@/pages/Competition/EditSession'))

const lazyLoad = (children) => {
  return (
    <Suspense fallback={<Spin style={{ 'position': 'absolute', 'top': '50%', 'left': '50%', 'transform': 'translate(-50%, -50%)', 'zIndex': '99' }} tip='Lodding...' />}>
      {children}
    </Suspense>
  )
}
export const rts = [
  {
    path: '/',
    authen: true,
    element: <Layouts />,
    title: '首页',
    children: [
      {
        index: true,
        element: lazyLoad(<DataOverview />)
      },
      {
        title: '数据总览',
        path: 'overview/dataOverview',
        element: lazyLoad(<DataOverview />)
      },
      {
        title: '赛事统计',
        path: 'overview/eventStatistics',
        element: lazyLoad(<EventStatistics />)
      },
      {
        title: '订单统计',
        path: 'overview/ordersReceivedStatistics',
        element: lazyLoad(<OrdersReceivedStatistics />)
      },
      {
        title: '赛事xxx',
        path: 'competition/publishEvent/editSession',
        element: lazyLoad(<EditSession />)
      },
      {
        title: '赛事列表',
        path: 'competition/listOfEvents',
        element: lazyLoad(<ListOfEvents />)
      },
      {
        title: '赛事发布',
        path: 'competition/listOfEvents/publishEvent',
        element: lazyLoad(<PublishEvent />),
      },
      {
        title: '报名管理',
        path: 'competition/registrationManagement',
        element: lazyLoad(<RegistrationManagement />)
      },
      {
        title: '订单详情',
        path: 'competition/registrationManagement/orderDetail',
        element: lazyLoad(<OrderDetail />)
      },
      {
        title: '成绩管理',
        path: 'competition/performanceManagement',
        element: lazyLoad(<PerformanceManagement />)
      },
      {
        title: '成绩详情',
        path: 'competition/performanceManagement/performanceManagementEdit',
        element: lazyLoad(<PerformanceManagementEdit />)
      },
      {
        title: '表单项管理',
        path: 'competition/applicantInformationManagement',
        element: lazyLoad(<ApplicantInformationManagement />)
      },
      {
        title: '赛事服务',
        path: 'competition/eventService',
        element: lazyLoad(<EventService />)
      },
      {
        title: '条款须知',
        path: 'competition/termsNotice',
        element: lazyLoad(<TermsNotice />)
      },
      {
        title: '协议条款',
        path: 'competition/TermsAgreement',
        element: lazyLoad(<TermsAgreement />)
      },
      {
        title: '邀请码管理',
        path: 'competition/invitationCodeManagement',
        element: lazyLoad(<InvitationCodeManagement />)
      },
      // {
      //   title: '预览组管理',
      //   path: 'competition/previewGroupManagement',
      //   element: lazyLoad(<PreviewGroupManagement/>)
      // },
      {
        title: '黑名单管理',
        path: 'competition/blacklistManagement',
        element: lazyLoad(<BlacklistManagement />)
      },
      {
        title: '系列赛标签库',
        path: 'competition/taglistManagement',
        element: lazyLoad(<TaglistManagement />)
      },
      {
        title: '账号管理',
        path: 'userControl/accountManagement',
        element: lazyLoad(<AccountManagement />)
      },
      {
        title: '账号详情',
        path: 'userControl/accountManagement/accountDetails',
        element: lazyLoad(<AccountDetails />)
      },
      {
        title: '注销申请',
        path: 'userControl/accountManagement/cancellationApplication',
        element: lazyLoad(<CancellationApplication />)
      },
      /* 用户模块 */
      {
        title: '中台管理员账号',
        path: 'userControl/centerManager',
        element: lazyLoad(<CenterManager />)
      },
      {
        title: '账号详情',
        path: 'userControl/centerManager/cmAccountDetails',
        element: lazyLoad(<CMAccountDetails />)
      },
      // {
      //   title: '后台管理员',
      //   path: 'userControl/backgroundAdministrator',
      //   element: lazyLoad(<BackgroundAdministrator />)
      // },
      /* 新闻模块 */
      {
        title: '分类管理',
        path: 'journalism/sortManagement',
        element: lazyLoad(<SortManagement />)
      },
      {
        title: '内容管理',
        path: 'journalism/contentManagement',
        element: lazyLoad(<ContentManagement />)
      },
      {
        title: '发布内容',
        path: 'journalism/contentManagement/publishContent',
        element: lazyLoad(<PublishContent />)
      },
      /* 审核 */
      {
        title: '报名审核',
        path: 'audit/registrationReview',
        element: lazyLoad(<RegistrationReview />)
      },
      {
        title: '报名人信息详情',
        path: 'audit/registrationReview/applicantInformationDetails',
        element: lazyLoad(<ApplicantInformationDetails />)
      },
      {
        title: '中台赛事审核',
        path: 'audit/middlegroundEventAudit',
        element: lazyLoad(<MiddlegroundEventAudit />)
      },
      {
        title: '赛事详情',
        path: 'audit/middlegroundEventAudit/detailsOfMidStageEvents',
        element: lazyLoad(<DetailsOfMidStageEvents />)
      },
      {
        title: '评论审核',
        path: 'audit/commentReview',
        element: lazyLoad(<CommentReview />)
      },
      {
        title: '退款审核',
        path: 'audit/RefundAudit',
        element: lazyLoad(<RefundAudit />)
      },
      {
        title: '退款订单详情',
        path: 'audit/RefundAudit/detailsRefundAudit',
        element: lazyLoad(<DetailsRefundAudit />)
      },
      /* 系统板块 */
      {
        title: '海报管理',
        path: 'system/postersManagement',
        element: lazyLoad(<PostersManagement />)
      },
      {
        title: '编辑海报',
        path: 'system/postersManagement/editThePoster',
        element: lazyLoad(<EditThePoster />)
      },
      {
        title: '推送管理',
        path: 'system/pushTheManagement',
        element: lazyLoad(<PushTheManagement />)
      },
      {
        title: '意见反馈',
        path: 'system/feedback',
        element: lazyLoad(<Feedback />)
      },
      {
        title: '举报管理',
        path: 'system/reportManagement',
        element: lazyLoad(<ReportManagement />)
      },
      {
        title: '素材中心',
        path: 'system/materialCenter',
        element: lazyLoad(<MaterialCenter />)
      },
      // {
      //   title: '短信管理',
      //   path: 'system/SMSManagement',
      //   element: lazyLoad(<SMSManagement />)
      // },
      {
        title: '播报管理',
        path: 'system/broadcast',
        element: lazyLoad(<Broadcast />)
      },
      {
        title: '横栏广告管理',
        path: 'system/advertising',
        element: lazyLoad(<Advertising />)
      },
      {
        title: '编辑横栏广告',
        path: 'system/advertising/editAdvertising',
        element: lazyLoad(<EditAdvertising />)
      },
      {
        title: '问答管理',
        path: 'system/editQa',
        element: lazyLoad(<EditQa />)
      },
      // {
      //   title: '编辑问答',
      //   path: 'system/qa/editQa',
      //   element: lazyLoad(<EditQa />)
      // },
      {
        title: '钱包管理',
        path: 'system/wallet',
        element: lazyLoad(<Wallet />)
      },
      {
        title: '钱包流水',
        path: 'system/wallet/walletRunning',
        element: lazyLoad(<WalletRunning />)
      },
      {
        title: '钱包详情',
        path: 'system/wallet/walletDetail',
        element: lazyLoad(<WalletDetail />)
      },
      {
        title: '订单中心',
        path: 'system/order',
        element: lazyLoad(<Order />)
      },
      {
        title: '金币管理',
        path: 'system/gold',
        element: lazyLoad(<Gold />)
      },
      {
        title: '关闭金币服务记录',
        path: 'system/gold/closeGold',
        element: lazyLoad(<CloseGold />)
      },
      {
        title: '金币账户详情',
        path: 'system/gold/detailGold',
        element: lazyLoad(<DetailGold />)
      },
      {
        title: '日志管理',
        path: 'system/logManagement',
        element: lazyLoad(<LogManagement />)
      },
      {
        title: '字典管理',
        path: 'system/dictionaryManagement',
        element: lazyLoad(<DictionaryManagement />)
      },
      {
        title: '相册管理',
        path: 'system/photo',
        element: lazyLoad(<Photo />)
      },
      {
        title: '文件下载管理',
        path: 'system/upload',
        element: lazyLoad(<Upload />)
      },
      {
        title: '菜单管理',
        path: 'jurisdiction/menuManagement',
        element: lazyLoad(<MenuManagement />)
      },
      {
        title: '后台账号',
        path: 'jurisdiction/backAccount',
        element: lazyLoad(<BackAccount />)
      },
      {
        title: '广告赞助商信息管理',
        path: 'system/contentManagement',
        element: lazyLoad(<JcontentManagement />)
      },
      {
        title: '结算记录',
        path: 'accountChecking/settlementRecords',
        element: lazyLoad(<SettlementRecords />)
      },
      {
        title: '结算详情',
        path: 'accountChecking/settlementRecords/billingDetails',
        element: lazyLoad(<BillingDetails />)
      },
      {
        title: '官网配置',
        path: 'set/setting',
        element: lazyLoad(<Setting />)
      },
      {
        path: '/about',
        element: lazyLoad(<About />)
      }
    ]
  },
  {
    path: '/about',
    authen: true,
    element: <About />,
    title: 'about页',
    children: [

    ]
  },
  {
    path: '/login',
    element: <Login />,
    meta: {
      title: '用户登录'
    }
  },
  {
    path: '*',
    element: <Notfond />,
    title: '404 NotFond'
  }
]

// export { rts, publicRoute }