import { message } from 'antd'
import axios from 'axios'
import qs from 'qs'

// 环境切换
// axios.defaults.baseURL = 'http://192.168.2.95:61010'
// axios.defaults.baseURL = 'http://file.hpaopao.com'
// 请求超时
axios.defaults.timeout = 15000
// post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8' // application/json;charset=utf-8  // application/x-www-form-urlencoded;charset=UTF-8
// axios.defaults.headers.put['Content-Type'] = 'application/json;charSet=UTF-8'
axios.defaults.headers.delete['Content-Type'] = 'application/json;charSet=UTF-8'

// 请求拦截器
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  var upload_file = config.upload;
  console.log('upload_file---', upload_file, config.upload)
  let header = {
    'domain-scope': 'ten-miles.marathonbm.com',
    'Authorization': token,
    'req-device-version': 'PC',
    'File-Upload-Type': upload_file == undefined ? '' : upload_file,
  }
  if (token) {
    config.headers = header
  }
  return config
},
  function (error) { return Promise.reject(error) })

// 响应拦截器
axios.interceptors.response.use(response => {
  // if (response) {
  //   switch (response.data.S) {
  //     case 2000: console.log('登录成功'); JSON.stringify(localStorage.setItem('userInfo')); break;
  //     case 5002: console.log('无用户被封禁'); break;
  //     case 5003: console.log('未登录'); break;
  //     case 4003: console.log('验证码错误'); break;
  //     case 500: console.log('获取验证码失败'); break;
  //     default: console.log('0'); break;
  //   }
  // }
  console.warn('☌响应成功☍', response.data)
  return response
}, function (error) {
  console.warn('☌响应失败☍', error.data);
  // message.error('网络不佳，请稍后重试！')
  return Promise.reject(error)
})

// get请求
function get(url, params) {
  if (url.indexOf('{id}') > -1) {
    url = url.replace('{id}', params['id'])
    params = {}
  }
  let header = {
    'domain-scope': 'ten-miles.marathonbm.com',
    'req-device-version': 'PC',
    'Accept': 'application/json',
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      }, { headers: header })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}
// post请求 application/x-www-form-urlencoded 格式
function postForm(url, params) {
  let header = {
    'req-device-version': 'PC',
    'domain-scope': 'ten-miles.marathonbm.com',
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, qs.stringify(params, { indices: false }), { headers: header })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

// post请求 application/json格式
function post(url, params) {
  if (url.indexOf('{id}') > -1) {
    url = url.replace('{id}', params['id'])
    params = {}
  }

  let header = {
    'req-device-version': 'PC',
    'domain-scope': 'ten-miles.marathonbm.com',
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=utf-8'
  }
  return new Promise((resolve, reject) => {
    axios.post(url, params, { headers: header })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });

}

function put(url, params) {
  let header = {
    'req-device-version': 'PC',
    'domain-scope': 'ten-miles.marathonbm.com',
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=utf-8'
  }
  return new Promise((resolve, reject) => {
    axios.put(url, params, { headers: header })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}

function putForm(url, params) {
  let header = {
    'req-device-version': 'PC',
    'domain-scope': 'ten-miles.marathonbm.com',
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
  return new Promise((resolve, reject) => {
    axios
      .put(url, qs.stringify(params, { indices: false }), { headers: header })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

function del(url, params) {
  if (url.indexOf('{id}') > -1) {
    url = url.replace('{id}', params['id'])
    params = {}
  }
  return new Promise((resolve, reject) => {
    axios.delete(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

// 传图片
function upload(url, params) {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    upload: 'xlsx'
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, config)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}


export {
  get,
  post,
  postForm,
  put,
  putForm,
  del,
  upload
}
