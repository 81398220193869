// import { get, post } from './http'
import { get, post, postForm, put, putForm, del, upload } from './request'

var $api = []

// 登录
$api.login = params => get('/api/slm/admin', params)

//修改个人信息
$api.setInfo = (params) => put('/api/api-cmc/admin/aus/sou-set', params)

// 测试
$api.test = params => get('/api/api-cmc/api/students', params)

//用户列表
$api.userList = params => get('/api/api-u/admin/user/afp', params)

//用户列表-账号封禁
$api.banList = (params, endTime, cause) => put(`/api/api-u/admin/user/ban?uid=${params}&endTime=${endTime}&cause=${cause}`)

//解禁
$api.closeBan = params => putForm(`/api/api-u/admin/user/ban-cancel/${params}`)

//用户详情
$api.detailUser = params => get(`/api/api-u/admin/user/detail/${params}`)

//用户列表-报名人信息
$api.banInfo = params => get(`/api/api-cmc/admin/user-contacts/list/${params}`)

//用户列表-报名人信息修改
$api.infoEdit = params => post('/api/api-cmc/admin/user-contacts/edit', params)

//用户注销/申请列表
$api.alrList = params => get('/api/api-u/admin/alr/page', params)

//钱包管理-钱包汇总
$api.summaryList = params => get('/api/api-pay/admin/wallet/summary', params)

//钱包管理-钱包列表
$api.walletList = params => get('/api/api-pay/admin/wallet/wrs', params)

//钱包管理-冻结/解冻
$api.walletfuf = params => post(`/api/api-pay/admin/wallet/fuf/${params}`)

//钱包列表-绑定账户
$api.walletfbaList = params => get(`/api/api-pay/admin/wallet/fba/${params}`)

//钱包列表-充值/扣除金额
$api.walletwr = (addition, amount, reason, remark, wid) => post(`/api/api-pay/admin/wallet/wr?addition=${addition}&amount=${amount}&reason=${reason}&remark=${remark}&wid=${wid}`)

//钱包列表-后台管理员开通钱包
$api.walletOpen = (params) => post(`/api/api-pay/admin/wallet/cow?phone=${params}`)

//钱包管理-钱包流水
$api.walletwarList = params => get('/api/api-pay/admin/wallet/war', params)

//钱包管理-钱包流水 提现详情
$api.walletwarDetail = params => get(`/api/api-pay/admin/wallet/rwa-detail/${params}`)

//钱包管理-钱包流水-提现通过/拒绝
$api.walletwva = (id, params, applyAmount, balance, remark, title) => post(`/api/api-pay/admin/wallet/wva?id=${id}&adopt=${params}&adopt=${applyAmount}&balance=${balance}&remark=${remark}&title=${title}`)
// $api.walletwva = params => post('/api/api-pay/admin/wallet/wva', params)

//字典列表接口
$api.dictList = params => get('/api/api-cmc/admin/dict/list', params)

/* 添加或更新条款、须知、声明 */
$api.addTerms = params => post('/api/api-cmc/admin/template/sou', params)

/* 获取条款、须知、声明列表 */
$api.getTerms = params => get('/api/api-cmc/admin/template/page', params)

/* 获取系列赛标签*/
$api.getTags = params => get('/api/api-cmc/admin/mh-classify/page', params)

//新增系列赛标签
$api.addTags = params => post('/api/api-cmc/admin/mh-classify/sou', params)

//删除系列赛标签
$api.delTags = params => del(`/api/api-cmc/admin/mh-classify/del/${params}`)

//发布赛事
$api.addEvent = params => post('/api/api-cmc/admin/mh/sou', params)

//添加/编辑项目
$api.addEntries = params => post('/api/api-cmc/admin/entries/sou', params)

/* 发布赛事 - 添加项目 - 获取报名方式列表 */
$api.getRegistrationWay = params => get('/api/api-cmc/admin/dict/list', params)

//[赛事列表]
$api.eventList = params => get('/api/api-cmc/admin/mh/page', params)

//赛事列表=>赛事列表导出
$api.exportList = params => get('/api/api-cmc/admin/mh/export-data')

//赛事列表=>赛事列表排序
$api.assList = (params, id, self) => post(`/api/api-cmc/admin/mh/ass?direction=${params}&id=${id}&self=${self}`)

//赛事列表=>发布或取消发布赛事
$api.publishedCancel = params => post(`/api/api-cmc/admin/mh/publish/${params}`)

//赛事列表=>取消赛事或恢复赛事
$api.restoreCancal = (params, id) => post(`/api/api-cmc/admin/mh/acm?delay=${params}&id=${id}`)

//赛事列表=>取消延期或赛事延期
$api.delayCancal = (params, id) => post(`/api/api-cmc/admin/mh/adm?delay=${params}&id=${id}`)

//赛事列表=>赛事项目详情
$api.entriesInfo = params => get(`/api/api-cmc/admin/entries/${params}`)

//赛事列表=>删除赛事
$api.delList = params => del(`/api/api-cmc/admin/mh/del/${params}`)

//发布或取消发布赛事
// $api.publish = params => post('/api/admin//publish/{id}', params)

//赛事列表=>获取赛事详情
$api.eventDetail = params => get(`/api/api-cmc/admin/mh/detail/${params}`)

//赛事列表=>生成完赛证书
$api.certEvent = params => put(`/api/api-cmc/admin/mh/gen-cert/${params}`)

//赛事列表-赛事项目列表
$api.projectList = params => get('/api/api-cmc/admin/entries/page', params)

//赛事列表-赛事项目-表单列表
$api.FormList = (params, id) => get(`/api/api-cmc/admin/entries/forms/${params}/${id}`)

//赛事列表-赛事项目-表单列表-表单子value值编辑
$api.FormAdd = (params, values) => post(`/api/api-cmc/admin/entries/forms-value/update?id=${params}&values=${values}`)

//赛事列表-赛事项目列表=>添加赛事项目
$api.addProject = params => post('/api/api-cmc/admin/entries/sou', params)

//赛事列表-赛事项目列表=>增值服务项目 
$api.adsList = params => get('/api/api-cmc/admin/ads/list', params)

// 获取赛事预览码
$api.adsPreview = params => get(`/api/api-cmc/admin/mh/preview/${params}`)

//增值服务项目（赛事服务项）-列表
$api.adsPage = params => get('/api/api-cmc/admin/ads/page', params)

//增值服务项目（赛事服务项）-新增/编辑
$api.addAds = params => post('/api/api-cmc/admin/ads/sou', params)

//增值服务详情
$api.adsDetail = params => get(`/api/api-cmc/admin/ads/detail/${params}`)

//删除增值服务
$api.delAds = params => del(`/api/api-cmc/admin/ads/del/${params}`)

//赛事列表-赛事项目=>新增/编辑增值服务项目
$api.addPage = params => post('/api/api-cmc/admin/ads/sou', params)

//赛事列表-赛事项目=>新增/编辑表单项
$api.addForm = params => post('/api/api-cmc/admin/d-form/sou', params)

//赛事列表-赛事项目=>获取动态表单详情
$api.detailForm = params => get(`/api/api-cmc/admin/d-form/detail/${params}`)

//赛事列表-赛事项目详情
$api.entriesDetail = params => get(`/api/api-cmc/admin/entries/detail/${params}`)

//赛事列表-赛事项目报名开关
$api.entriesFlag = params => post(`/api/api-cmc/admin/entries/co/${params}`)

//赛事列表-赛事项目删除项目
$api.delEntries = params => post(`/api/api-cmc/admin/entries/del/${params}`)

//赛事列表-赛事项目排序
$api.entriesAss = (params, id) => post(`/api/api-cmc/admin/entries/ass?direction=${params}&id=${id}`)

//获取简单赛事列表--条件查询时用
$api.searchEvent = params => get('/api/api-cmc/admin/mh/afsm', params)

//获取简单赛事项目列表--条件查询时用
$api.searchProject = params => get(`/api/api-cmc/admin/entries/afse/${params}`)

//报名管理列表
$api.signList = params => get('/api/api-cmc/admin/mo-ods/list', params)

//报名管理列表-修改订单状态
$api.editStatusList = (id, params) => put(`/api/api-cmc/admin/mo-ods/mos?id=${id}&state=${params}`)

//报名管理-获取赛事订单下的报名用户列表
$api.detailUsers = params => get(`/api/api-cmc/admin/mo-ods/users/${params}`)

//报名管理-获取子订单详情
$api.detailSub = params => get('/api/api-cmc/admin/mh-ods/u-sub-detail', params)

//报名管理-获取子订单详情修改
$api.editSub = params => post('/api/api-cmc/admin/mh-ods/u-update-sub', params)

//报名管理-导入参赛号
$api.importRn = params => upload('/api/api-cmc/admin/mo-ods/import-rn', params)

//报名管理-批量更改状态
$api.importRn1 = params => upload('/api/api-cmc/admin/mo-ods/import-os', params)

//报名管理-导入抽签
$api.importRn2 = params => upload('/api/api-cmc/admin/mo-ods/import-dl', params)

$api.importRn3 = params => upload('/api/api-cmc/admin/mo-ods/import-ieu', params)

//报名管理-预退款
$api.refund = params => get('/api/api-cmc/admin/mh-ods/pre-refund', params)

//报名管理-子订单预退款
$api.refundSub = params => get('/api/api-cmc/admin/mh-ods/pre-sub-refund', params)

//报名管理-退款
$api.yesRefund = (odId, amount, refundType, reason, subOdId) => postForm(`/api/api-cmc/admin/mh-ods/refund?odId=${odId}&refundAll=true&amount=${amount}&refundType=${refundType}&reason=${reason}&subOdId=${subOdId}`)

//报名管理-子订单退款
$api.yesORefund = (odId, amount, refundType, reason, subOdId) => postForm(`/api/api-cmc/admin/mh-ods/refund?odId=${odId}&refundAll=false&amount=${amount}&refundType=${refundType}&reason=${reason}&subOdId=${subOdId}`)


//报名管理-导出各种模版
$api.importTemplate = params => get('/api/api-cmc/admin/mo-ods/export-tf', params)

//报名管理-导出并提交
$api.importSubmit = params => get('/api/api-cmc/admin/mo-ods/export-submit-data', params)

//报名管理-详情
$api.detailOds = params => get(`/api/api-cmc/admin/mo-ods/detail/${params}`)

//报名管理-修改订单状态 
$api.editType = params => post('/api/api-cmc/admin/mo-ods/import-os', params)

//报名表单项-列表
$api.dformList = params => get('/api/api-cmc/admin/d-form/page', params)

//报名表单项-新增/编辑
$api.addDform = params => post('/api/api-cmc/admin/d-form/sou', params)

//报名表单项-校验是否有重复的column
$api.accform = params => get('/api/api-cmc/admin/d-form/acc', params)

//报名表单项-排序
$api.assForm = (params, id) => post(`/api/api-cmc/admin/d-form/ass?direction=${params}&id=${id}`)

//报名表单项-删除
$api.delForm = params => del(`/api/api-cmc/admin/d-form/del/${params}`)

//报名表单项-详情
$api.detailDForm = params => get(`/api/api-cmc/admin/d-form/detail/${params}`)

//条款须知列表
$api.templateList = params => get('/api/api-cmc/admin/template/page', params)

//条款须知列表-编辑/新增
$api.addTemplate = params => post('/api/api-cmc/admin/template/sou', params)

//条款须知列表-详情
$api.detailTemplate = params => get(`/api/api-cmc/admin/template/detail/${params}`)

//条款须知-删除
$api.delTemplate = params => del(`/api/api-cmc/admin/template/del/${params}`)

//协议列表
$api.findsList = params => get('/api/api-cmc/admin/app-at/finds', params)

//协议列表-编辑/新增
$api.addFinds = params => post('/api/api-cmc/admin/app-at/sou', params)

//协议列表-详情
$api.detailFinds = params => get(`/api/api-cmc/admin/app-at/finds/rich-text/${params}`)

//协议列表-删除
$api.delFinds = params => del(`/api/api-cmc/admin/app-at/del/${params}`)

//黑名单管理列表
$api.blackList = params => get('/api/api-cmc/admin/blacklist/page', params)

//黑名单管理-导入
$api.importBl = params => upload('/api/api-cmc/admin/blacklist/import-bl', params)

//黑名单管理-添加
$api.addBlack = params => post('/api/api-cmc/admin/blacklist/sou', params)

//黑名单管理-移除
$api.delBlack = params => del(`/api/api-cmc/admin/blacklist/del/${params}`)

//邀请码列表
$api.codeList = params => get('/api/api-cmc/admin/invite-code/page', params)

//邀请码生成
$api.addCode = params => post('/api/api-cmc/admin/invite-code/generate', params)

//邀请码删除
$api.delCode = params => del(`/api/api-cmc/admin/invite-code/del?ids=${params}`)

//意见反馈列表
$api.feedbackList = params => get('/api/api-cmc/admin/feedback/page', params)

//意见反馈回复
$api.replyFeedback = (params, id) => put(`/api/api-cmc/admin/feedback/reply?content=${params}&id=${id}`)

//创建单个文件目录
$api.createAlbum = params => post('/api/api-fe/api-file/album/create', params)

//更新目录名称
$api.updateAlbum = params => post('/api/api-fe/api-file/album/update', params)

//删除单个目录
$api.delAlbum = params => del('/api/api-fe/api-file/album/del/{id}', params)

//删除单个文件
$api.delFile = params => del('/api/api-fe/api-file/album/file-del/{id}', params)

//删除多个文件
$api.delFiles = params => postForm(`/api/api-fe/api-file/album/files-del?ids=${params}`)

//获取文件目录里的文件
$api.albums = params => get('/api/api-fe/api-file/albums', params)

//根据商户获取文件目录
$api.mainAlbum = params => get('/api/api-fe/api-file/albums/{id}', params)

//字典管理列表
$api.dictPage = params => get('/api/api-cmc/admin/dict/page', params)

//字典管理-添加
$api.addDict = params => post('/api/api-cmc/admin/dict/sou', params)

//字典管理-删除
$api.delDict = params => del(`/api/api-cmc/admin/dict/del/${params}`)

//菜单管理列表
$api.menuPage = params => get('/api/api-cmc/admin/menu/list', params)

//简单菜单列表
$api.menuFsm = params => get('/api/api-cmc/admin/menu/fsm', params)

//菜单排序
$api.assMenu = (id, params) => post(`/api/api-cmc/admin/menu/ass?id=${id}&direction=${params}`)

//登陆菜单列表
$api.menuLogin = params => get('/api/api-cmc/admin/login-sus/menus', params)

//添加主菜单/子菜单
$api.addMenu = params => post('/api/api-cmc/admin/menu/sou', params)

//添加菜单权限
$api.addPermission = params => post('/api/api-cmc/admin/menu-permission/sou', params)

//菜单管理删除
$api.delMenu = params => del(`/api/api-cmc/admin/menu/del/${params}`)

//菜单管理权限列表删除
$api.delPermission = params => del(`/api/api-cmc/admin/menu-permission/del/${params}`)

//后台账号管理列表
$api.ausPage = params => get('/api/api-cmc/admin/aus/page', params)

//添加后台账号
$api.addAus = params => post('/api/api-cmc/admin/aus/sou', params)

//删除后台账号
$api.delAus = params => del(`/api/api-cmc/admin/aus/del/${params}`)

//后台账号状态开关
$api.assAus = params => post(`/api/api-cmc/admin/aus/eoc/${params}`)

//后台账号详情
$api.detailAus = params => get(`/api/api-cmc/admin/aus/detail/${params}`)

//后台账号重置密码
$api.resetPwdAus = params => post(`/api/api-cmc/admin/aus/reset-pwd?id=${params}`)

//角色列表
$api.asrPage = params => get('/api/api-cmc/admin/asr/page', params)

//添加/编辑角色
$api.addAsr = params => post('/api/api-cmc/admin/asr/sou', params)

//删除角色
$api.delAsr = params => del(`/api/api-cmc/admin/asr/del/${params}`)

//角色详情
$api.detailAsr = params => get(`/api/api-cmc/admin/asr/detail/${params}`)

//举报管理列表
$api.rcPage = params => get('/api/api-cmc/admin/rc/page', params)

//举报管理列表/封禁||已读
$api.rcBan = (id, params) => put(`/api/api-cmc/admin/rc/ban-read?id=${id}&ban=${params}`)

//推送管理列表
$api.pushPage = params => get('/api/api-cmc/admin/push/page', params)

//推送管理-新增
$api.addPush = params => post('/api/api-cmc/admin/push/sou', params)

//推送管理-取消推送
$api.cancelPush = params => post(`/api/api-cmc/admin/push/cancel/${params}`)

//推送管理-删除推送
$api.delPush = params => post(`/api/api-cmc/admin/push/del/${params}`)

//banner列表
$api.bannerPage = params => get('/api/api-cmc/admin/banner/page', params)

//新增/编辑banner
$api.addBanner = params => post('/api/api-cmc/admin/banner/sou', params)

//banner排序
$api.assBanner = (params, id) => post(`/api/api-cmc/admin/banner/ass?direction=${params}&id=${id}`)

//banner删除
$api.delBanner = params => del(`/api/api-cmc/admin/banner/del/${params}`)

//banner详情
$api.detailBanner = params => get(`/api/api-cmc/admin/banner/detail/${params}`)

//banner开关
$api.ocbBanner = params => post(`/api/api-cmc/admin/banner/ocb?id=${params}`)

//报名审核列表
$api.affPage = params => get('/api/api-cmc/admin/mo-aff/page', params)

//报名审核列表通过/拒绝
$api.araPage = (id, params, position) => post(`/api/api-cmc/admin/mo-vupr/ara?id=${id}&pass=${params}&position=${position}`)

//报名审核详情
$api.detailAff = params => get(`/api/api-cmc/admin/mo-aff/detail/${params}`)

//退款审核
$api.odsPage = params => get('/api/api-cmc/admin/mo-ods/rar', params)

//退款审核-通过/拒绝
$api.odsPass = (id, params, cause) => post(`/api/api-cmc/admin/mh-ods/refund/rav?odId=${id}&pass=${params}&cause=${cause}`)

//退款详情
$api.odsDetail = (params) => get(`/api/api-cmc/admin/mo-ods/rad/${params}`)

//退款详情用户购买的增值服务
$api.odsGa = (params) => get(`/api/api-cmc/admin/mo-ods/ga/${params}`)

//中台赛事审核
$api.otePage = params => get('/api/api-cmc/admin/mh-ote/page', params)

//中台赛事审核通过
$api.AcaOte = params => postForm('/api/api-cmc/admin/mh-ote/aca', params)

//中台赛事审核拒绝
$api.AraOte = params => postForm('/api/api-cmc/admin/mh-ote/ara', params)

//中台赛事详情
$api.detailOte = params => get(`/api/api-cmc/admin/mh-ote/detail/${params}`)

//新闻栏目列表
$api.amsList = params => get('/api/api-cmc/admin/ams/list', params)

//新闻栏目新增/编辑
$api.amsAdd = params => post('/api/api-cmc/admin/ams/sou', params)

//新闻栏目删除
$api.delAms = params => del(`/api/api-cmc/admin/ams/del/${params}`)

//新闻内容列表
$api.articleList = params => get('/api/api-cmc/admin/article/page', params)

//新闻评论状态开关
$api.aeccfArticle = params => post(`/api/api-cmc/admin/article/aeccf?id=${params}`)

//添加新闻
$api.amsArticle = params => post('/api/api-cmc/admin/article/sou', params)

//新闻前端是否可展示
$api.asdArticle = params => post(`/api/api-cmc/admin/article/asd?id=${params}`)

//新闻删除
$api.delArticle = params => del(`/api/api-cmc/admin/article/del/${params}`)

//新闻排序
$api.assArticle = (params, direction) => post(`/api/api-cmc/admin/article/ass?id=${params}&direction=${direction}`)

//新闻详情
$api.detailArticle = params => get(`/api/api-cmc/admin/article/detail/${params}`)

/* 上传图片 */
$api.fileUpload = params => upload('/api/api-fe/api-file/upload/aliyun', params)

// 逆地址解析 - 测试
$api.geo = params => get('https://restapi.amap.com/v3/geocode/geo', params)

// 上传图片
$api.upload = params => upload('https://gateway.qoqq.com/api-f/files/upload', params)

//播报管理-配置
$api.broadcastConfig = params => get('/api/api-cmc/admin/bcc/config', params)

//播报管理-新增配置
$api.broadcastConfigAdd = params => post('/api/api-cmc/admin/bcc/sou', params)

//播报管理-删除配置
$api.broadcastConfigDel = params => del(`/api/api-cmc/admin/bcc/adc/${params}`)

//播报管理-广播信息
$api.broadcast = params => get('/api/api-cmc/admin/bcr/records', params)

//播报管理-广播信息删除
$api.broadcastDel = params => del(`/api/api-cmc/admin/bcr/adc/${params}`)

//播报管理-广播信息添加
$api.broadcastAdd = params => post('/api/api-cmc/admin/bcr/sou', params)

//订单中心
$api.orderList = params => get('/api/api-pay/admin/unified-order/ors', params)

//问答列表
$api.qasList = params => get('/api/api-cmc/admin/qas/list', params)

//问答-新增
$api.qasAdd = params => post('/api/api-cmc/admin/qas/sou', params)

//问答-删除

$api.qasDel = params => del(`/api/api-cmc/admin/qas/del/${params}`)

//赞助商管理
$api.sponsor = params => get('/api/api-cmc/admin/sponsor/modules', params)

//赞助商管理删除
$api.delSponsor = params => del(`/api/api-cmc/admin/sponsor/modules/del/${params}`)

//新增赞助商栏目
$api.sponsorAdd = (params, title, id) => post(`/api/api-cmc/admin/sponsor/modules/sou?lineColumn=${params}&title=${title}&id=${id}`)

//赞助商栏目排序
$api.assSponsor = (params, direction) => post(`/api/api-cmc/admin/sponsor/modules/ass?id=${params}&direction=${direction}`)

//赞助商列表
$api.sponsorList = params => get('/api/api-cmc/admin/sponsor-logo/ags', params)

//赞助商信息
$api.sponsorInfoList = params => get('/api/api-cmc/admin/sponsor/modules/asc/get', params)

//赞助商列表添加
$api.sponsorListAdd = params => post('/api/api-cmc/admin/sponsor-logo/sou', params)

//赞助商列表排序
$api.sponsorListAss = (params, direction) => post(`/api/api-cmc/admin/sponsor-logo/ass?id=${params}&direction=${direction}`)

//赞助商列表删除
$api.delSponsorList = params => del(`/api/api-cmc/admin/sponsor-logo/del/${params}`)

//赞助商列表批量删除
$api.batchDelSponsorList = params => del(`/api/api-cmc/admin/sponsor-logo/batch-del/${params}`)

//赞助商列表显示开关
$api.batchSponsorAss = params => put(`/api/api-cmc/admin/sponsor-logo/is-show/${params}`)

// 设置组委会赞助商信息
$api.sponsorListAsc = (params, openOneSponsor, openSponsor) => post(`/api/api-cmc/admin/sponsor/modules/asc?oneSponsorLogo=${params}&openOneSponsor=${openOneSponsor}&openSponsor=${openSponsor}`)

//清除系统缓存
$api.clearConfig = params => put('/api/api-cmc/admin/aca/clear-cache')

//创建相册/编辑相册属性
$api.creatAlbum = (params, title, desc) => post(`/api/api-cmc/admin/album/create`, params)

//相册列表
$api.albumList = params => get('/api/api-cmc/admin/album/page', params)

//相册删除
$api.albumDel = params => del(`/api/api-cmc/admin/album/del/${params}`)

//照片列表
$api.photosList = params => get('/api/api-cmc/admin/album/photos', params)

//照片删除
$api.photoDel = params => del(`/api/api-cmc/admin/album-photo/del/?ids=${params}`)

//上传照片
$api.photoAdd = params => upload('/api/api-cmc/admin/album-photo/upload', params)

//上传完成后修改照片信息
$api.photoInfo = params => post(`/api/api-cmc/admin/album-photo/rename`, params)

//将相片移动到其他相册
$api.photoMove = (params, ids) => put(`/api/api-cmc/admin/album-photo/mta?aid=${params}&ids=${ids}`)

//文件下载管理
$api.fileList = params => get('/api/api-cmc/admin/files/page', params)

//上传文件
$api.fileAdd = params => upload('/api/api-cmc/admin/files/upload', params)

//删除文件
$api.fileDel = params => del(`/api/api-cmc/admin/files/del/${params}`)

//成绩列表
$api.scoreList = params => get('/api/api-cmc/admin/score/page', params)

//成绩-导入
$api.scoreRn = params => upload('/api/api-cmc/admin/score/import', params)

//成绩-详情
$api.scoreDetail = params => get(`/api/api-cmc/admin/score/detail/${params}`)

//取消成绩
$api.scoreCancel = (params, direction) => post(`/api/api-cmc/admin/score/cancel/${params}`)

//编辑成绩
$api.scoreEdit = params => post('/api/api-cmc/admin/score/sou', params)

export { $api }
