import { useEffect, useState } from 'react'
import './login.less'
import { Form, Input, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { $api } from '@/config/api'
import { useStore } from '@/store'

const Login = () => {
  document.title = '用户登录'
  const icon1 = require('@/assets/images/loginImg/icon01.png')
  const icon2 = require('@/assets/images/loginImg/icon02.png')
  const iconTxt = require('@/assets/images/loginImg/txticon.png')
  const logo = require('@/assets/images/loginImg/11890@2x.png')
  const { navigationStore: NS } = useStore()

  const [cut, setCut] = useState(true)
  const [forgetPassword, setForgetPassword] = useState(false)
  const [verification, setVerification] = useState(true)
  const [count, setCount] = useState(60)
  // 点击切换icon
  const cutIcon = () => {
    setCut(!cut)
    setForgetPassword(false)
  }
  // 点击忘记密码||返回登录
  const TAPforgetThePassword = () => {
    setForgetPassword(!forgetPassword)
  }

  // 点击发送验证码
  var timer; //直接修改hook数据会乱(页面上会频繁重置)  先用这种方法处理
  const TAPverificationCode = () => {
    setVerification(!verification)
    let sum = 60
    timer = setInterval(() => {
      if (sum === 0) {
        clearInterval(timer)
        return
      }
      sum--
      setCount(sum)
    }, 100);
  }

  const navigate = useNavigate()
  const onFinish = (values) => {
    console.log('Success:', values);
    $api.login({
      adminType: 'COOPERATOR_ADMIN',
      param: values.username,
      password: values.password,
      remember: false,
      scope: 'MC_SCOPE'
    }).then(res => {
      if (res.code === 200) {
        localStorage.setItem('token', `Bearer ${res.data.access_token}`)
        localStorage.setItem('cpid', res.data.admin.cpid)
        localStorage.setItem('nickName', res.data.admin.nickName)
        localStorage.setItem('photo', res.data.admin.photo)
        localStorage.setItem('phone', res.data.admin.phone)
        localStorage.setItem('uid', res.data.admin.id)
        localStorage.setItem('gender', res.data.admin.gender)
        localStorage.setItem('loginTime', res.data.admin.lastLoginTime ? res.data.admin.lastLoginTime : '0000-00-00')

        if (res.data.admin.roleId && res.data.admin?.roles.length) {
          res.data.admin?.roles.map(item => {
            if (res.data.admin.roleId === item.id) {
              console.log(item.roleName)
              localStorage.setItem('identity', item.roleName)
            }
          })
        }
        $api.menuLogin().then(res => {
          if (res.code == 200) {
            navigate(res.data[0].children[0].href)
            let arr = res.data[0].children[0].href
            let newData = arr.substring(1)
            NS.navigateList = [{
              title: res.data[0].children[0].title,
              path: newData
            }]
            sessionStorage.setItem('navigateList', JSON.stringify(NS.navigateList))
            return
          } else {
            message.error(res.message)
          }
        })
        message.success('登录成功', 0.5)
      } else {
        message.error(res.message)
      }
    })

  };


  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  useEffect(() => {
    return () => {
      clearInterval(timer)
      // console.log('组件卸载了', timer)
    }
  })

  useEffect(() => {
    if (count === 0) {
      setVerification(true)
    }
  }, [count])
  return (
    <div className="login">
      <img className='logo' src={logo} alt="" />
      <div className='loginBg'>
        {/* <img className='loginIcon' src={cut ? icon1 : icon2} alt="" onClick={cutIcon} />
        <img className='txtIcon' src={iconTxt} alt="" /> */}
        <div className='accountLogin'>
          <span className='register'>{!forgetPassword ? '登 录' : '重置密码'}</span>

          <Form
            name="basic"
            // labelCol={{
            //   span: 24,
            // }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              style={forgetPassword && { 'marginBottom': '8px' }}
              name="username"
              rules={[
                {
                  required: true,
                  message: '请输入账号!',
                },
              ]}
            >
              <Input placeholder="请输入手机号/邮箱" style={{ 'height': '40px' }} />
            </Form.Item>

            {
              forgetPassword &&
              <Form.Item
                style={forgetPassword && { 'marginBottom': '8px', 'position': 'relative' }}
              >
                <Form.Item style={{ 'marginBottom': '0px' }}
                  name="verificationCode"
                  rules={[
                    {
                      required: true,
                      message: '请输入验证码!',
                    },
                  ]}
                >
                  <Input placeholder="请输入验证码" style={{ 'height': '40px' }} />
                </Form.Item>
                <Button type='link' className='verificationCode'
                  disabled={verification ? false : true}
                  onClick={TAPverificationCode}>
                  {verification ? '发送验证码' : `${count}秒后重新获取`}
                </Button>
              </Form.Item>
            }


            <Form.Item
              name="password"
              placeholder="请输入密码"
              rules={[
                {
                  required: true,
                  message: '请输入密码!',
                },
              ]}
            >
              <Input.Password placeholder="请输入密码" style={{ 'height': '40px', borderColor: '#b4b1b1' }} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ 'width': '256px', 'height': '40px' }}>
                登录
              </Button>
            </Form.Item>
          </Form>


          {/* <div className='forgetThePassword' onClick={TAPforgetThePassword}>{!forgetPassword ? '忘记密码' : '返回登录'}</div> */}
        </div>
      </div>
      <p className='copyright'>©2022~ All Rights Reserved.</p>
    </div>
  );
}

export default Login;