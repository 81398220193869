import { makeAutoObservable } from "mobx";
class RouteTable {
  rtList = [
    // {
    //   id: 1,
    //   icon: {
    //     title: 'zonglan',
    //     svg: '<path d="M832 896H192c-38.4 0-64-25.6-64-64V192c0-38.4 25.6-64 64-64h640c38.4 0 64 25.6 64 64v640c0 38.4-25.6 64-64 64zM544 544V832H832V544H544zM192 544V832h288V544H192zM544 192v288H832V192H544zM192 192v288h288V192H192z" p-id="1170"></path>'
    //   },
    //   title: '总览',
    //   path: '/overview',
    //   warn: false,
    //   isShow: true,
    //   children: [
    //     {
    //       id: '1-1',
    //       title: '数据总览',
    //       path: '/overview/dataOverview',
    //       children: [
    //         {
    //           id: '1-1-1',
    //           title: '按钮权限',
    //           authKey: 'shouye:edit',
    //         }
    //       ]
    //     },
    //     {
    //       id: '1-2',
    //       title: '赛事统计',
    //       path: '/overview/eventStatistics',
    //     },
    //     {
    //       id: '1-3',
    //       title: '订单统计',
    //       path: '/overview/ordersReceivedStatistics',
    //     },
    //   ],
    // },
    {
      id: 2,
      icon: "https://file.marathonbm.com/2000/201/file/2023_02/root.list.png",
      title: '赛事',
      path: '/competition',
      warn: false,
      isShow: true,
      children: [
        {
          id: '2-1',
          title: '赛事列表',
          path: '/competition/listOfEvents',
        },
        {
          id: '2-2',
          title: '报名管理',
          path: '/competition/registrationManagement'
        },
        // {
        //   id: '2-3',
        //   title: '成绩管理',
        //   path: '/competition/performanceManagement'
        // },
        {
          id: '2-4',
          title: '表单项管理',
          path: '/competition/applicantInformationManagement'
        },
        {
          id: '2-5',
          title: '赛事服务',
          path: '/competition/eventService'
        },
        {
          id: '2-6',
          title: '条款须知',
          path: '/competition/termsNotice'
        },
        {
          id: '2-3',
          title: '协议条款',
          path: '/competition/TermsAgreement'
        },
        {
          id: '2-7',
          title: '邀请码管理',
          path: '/competition/invitationCodeManagement'
        },
        // {
        //   id: '2-8',
        //   title: '预览组管理',
        //   path: '/competition/previewGroupManagement'
        // },
        {
          id: '2-9',
          title: '黑名单管理',
          path: '/competition/blacklistManagement'
        },
        {
          id: '2-10',
          title: '系列赛标签库',
          path: '/competition/taglistManagement'
        },
      ]
    },
    {
      id: 3,
      icon: {
        title: 'yonghu',
        svg: '<path d="M992 928h-704c-19.2 0-32-12.8-32-32v-160c0-25.6 12.8-44.8 32-57.6C512 576 768 576 992 678.4c19.2 12.8 32 32 32 57.6V896c0 19.2-12.8 32-32 32zM640 665.6c-108.8 0-217.6 25.6-320 70.4v128h640v-128c-102.4-44.8-211.2-70.4-320-70.4zM185.6 832H32c-19.2 0-32-12.8-32-32v-140.8c0-25.6 12.8-44.8 32-57.6C140.8 550.4 262.4 524.8 384 524.8v64c-108.8 0-217.6 25.6-320 70.4V768h121.6v64zM640 531.2C524.8 531.2 435.2 435.2 435.2 320S524.8 115.2 640 115.2s204.8 96 204.8 204.8c0 121.6-89.6 211.2-204.8 211.2z m0-352c-76.8 0-140.8 64-140.8 140.8s64 140.8 140.8 140.8 140.8-64 140.8-140.8c0-76.8-64-140.8-140.8-140.8zM384 480C268.8 480 172.8 384 172.8 268.8 172.8 153.6 268.8 64 384 64c32 0 64 6.4 96 19.2l-44.8 57.6c-70.4-32-153.6 6.4-185.6 76.8S256 377.6 332.8 403.2c12.8 6.4 32 12.8 51.2 12.8v64z" p-id="1260"></path>'
      },
      title: '用户',
      warn: false,
      isShow: true,
      path: '/userControl',
      children: [
        {
          id: '3-1',
          title: '账号管理',
          path: '/userControl/accountManagement'
        },
        {
          id: '3-2',
          title: '中台管理员',
          path: '/userControl/centerManager'
        },
        // {
        //   id: '3-3',
        //   title: '后台管理员',
        //   path: '/userControl/backgroundAdministrator'
        // },
      ],
    },
    // {
    //   id: 4,
    //   icon: {
    //     title: 'kefu',
    //     svg: '<path d="M576 940.8H512v-64h64c102.4 0 192-70.4 217.6-172.8h-121.6c-19.2 0-32-12.8-32-32v-256c0-19.2 12.8-32 32-32h121.6C768 230.4 620.8 121.6 460.8 153.6 345.6 172.8 249.6 262.4 230.4 384h121.6c19.2 0 32 12.8 32 32v256c0 19.2-12.8 32-32 32H192c-19.2 0-32-12.8-32-32v-256-6.4c12.8-192 185.6-339.2 377.6-326.4 172.8 12.8 307.2 153.6 326.4 326.4V678.4c-12.8 147.2-140.8 262.4-288 262.4zM704 448v192h96V448H704zM224 448v192H320V448H224z" p-id="6253"></path>'
    //   },
    //   title: '客服',
    //   warn: false,
    //   isShow: true,
    //   children: [],
    // },
    {
      id: 5,
      icon: {
        title: 'xinwen',
        svg: '<path d="M716.8 896H147.2c-32-6.4-57.6-32-51.2-64V192c0-32 19.2-57.6 51.2-64h569.6c32 6.4 51.2 32 51.2 64v640c0 32-19.2 57.6-51.2 64zM160 192v640H704V192H160z m403.2 480H256v-64h307.2v64zM499.2 512H256V448h243.2v64zM576 352H256v-64h320v64zM832 896h-128V320h128c38.4 0 64 25.6 64 64v448c0 38.4-25.6 64-64 64z m-64-512v448h64V384h-64z" p-id="1230"></path>'
      },
      title: '新闻',
      warn: false,
      isShow: true,
      path: '/journalism',
      children: [
        // {
        //   id: '5-1',
        //   title: '分类管理',
        //   path: '/journalism/sortManagement'
        // },
        {
          id: '5-2',
          title: '内容管理',
          path: '/journalism/contentManagement'
        }
      ],
    },
    {
      id: 6,
      icon: {
        title: 'shenhe',
        svg: '<path d="M800 960h-576c-32 0-64-25.6-64-57.6V121.6c0-32 32-57.6 64-57.6h339.2c19.2 0 32 6.4 44.8 19.2L844.8 320c12.8 12.8 19.2 25.6 19.2 44.8v537.6c0 32-32 57.6-64 57.6z m-576-832v768h576V384h-256V128h-320z m384 44.8V320h147.2L608 172.8zM416 755.2l-89.6-89.6 44.8-44.8 89.6 89.6L640 531.2l44.8 44.8-224 224-44.8-44.8z" p-id="6343"></path>'
      },
      title: '审核',
      warn: false,
      isShow: true,
      path: '/audit',
      children: [
        {
          id: '6-1',
          title: '报名审核',
          path: '/audit/registrationReview'
        },
        {
          id: '6-2',
          title: '中台赛事审核',
          path: '/audit/middlegroundEventAudit'
        },
        {
          id: '6-3',
          title: '评论审核',
          path: '/audit/commentReview'
        },
        {
          id: '6-4',
          title: '退款审核',
          path: '/audit/RefundAudit'
        },
      ],
    },
    {
      id: 7,
      icon: {
        title: 'xitong',
        svg: '<path d="M512 947.2l-390.4-217.6V294.4L512 76.8l390.4 217.6v435.2L512 947.2zM512 153.6L185.6 332.8v358.4L512 870.4l326.4-179.2V332.8L512 153.6zM512 704c-108.8 0-192-83.2-192-192s83.2-192 192-192 192 83.2 192 192-83.2 192-192 192z m0-320c-70.4 0-128 57.6-128 128s57.6 128 128 128 128-57.6 128-128-57.6-128-128-128z" p-id="1110"></path>'
      },
      title: '系统',
      warn: false,
      isShow: true,
      path: '/system',
      children: [
        {
          id: '7-1',
          title: '海报管理',
          path: '/system/postersManagement'
        },
        {
          id: '7-2',
          title: '推送管理',
          path: '/system/pushTheManagement'
        },
        {
          id: '7-3',
          title: '意见反馈',
          path: '/system/feedback'
        },
        {
          id: '7-4',
          title: '举报管理',
          path: '/system/reportManagement'
        },
        {
          id: '7-5',
          title: '素材中心',
          path: '/system/materialCenter'
        },
        // {
        //   id: '7-6',
        //   title: '短信管理',
        //   path: '/system/SMSManagement'
        // },
        {
          id: '7-7',
          title: '播报管理',
          path: '/system/broadcast'
        },
        {
          id: '7-8',
          title: '横栏广告管理',
          path: '/system/advertising'
        },
        {
          id: '7-9',
          title: '问答管理',
          path: '/system/editQa'
        },
        {
          id: '7-10',
          title: '钱包管理',
          path: '/system/wallet'
        },
        {
          id: '7-11',
          title: '订单中心',
          path: '/system/order'
        },
        {
          id: '7-12',
          title: '金币管理',
          path: '/system/gold'
        },
        {
          id: '7-13',
          title: '日志管理',
          path: '/system/logManagement'
        },
        {
          id: '7-14',
          title: '字典管理',
          path: '/system/dictionaryManagement'
        },
      ],
    },
    {
      id: 8,
      icon: {
        title: 'quanxian',
        svg: '<path d="M780.8 928l-44.8-44.8-89.6-89.6 44.8-44.8 89.6 89.6 179.2-179.2 44.8 44.8-224 224z m-172.8 0H128c-19.2 0-32-12.8-32-32v-147.2c0-25.6 12.8-44.8 32-57.6 192-89.6 403.2-108.8 608-57.6v64c-192-51.2-396.8-38.4-576 51.2v115.2h448v64zM512 544C390.4 544 288 441.6 288 320S390.4 96 512 96s224 102.4 224 224S633.6 544 512 544z m0-384C422.4 160 352 230.4 352 320S422.4 480 512 480s160-70.4 160-160S601.6 160 512 160z" p-id="6313"></path>'
      },
      title: '权限',
      warn: false,
      isShow: true,
      path: '/jurisdiction',
      children: [
        {
          id: '7-1',
          title: '菜单管理',
          path: '/jurisdiction/menuManagement'
        },
        {
          id: '7-2',
          title: '后台账号',
          path: '/jurisdiction/backAccount'
        },

      ],
    },
    {
      id: 9,
      icon: {
        title: 'duizhang',
        svg: '<path d="M704 896H192c-38.4 0-64-32-64-70.4V262.4C128 224 153.6 192 192 192h512c38.4 0 64 32 64 70.4v563.2c0 38.4-25.6 70.4-64 70.4zM192 256v576h512V256H192z m704 512h-64V128H256V64h576c38.4 0 64 25.6 64 64v640z m-416-6.4h-64v-96h-128v-64h128v-64h-128v-64H384L294.4 364.8l51.2-38.4 102.4 128 102.4-128 51.2 38.4L512 473.6h96v64h-128v64h128v64h-128v96z" p-id="6193"></path>'
      },
      title: '对账',
      path: '/accountChecking',
      warn: false,
      isShow: true,
      children: [
        {
          id: '9-1',
          title: '结算记录',
          path: '/accountChecking/settlementRecords'
        },
      ],
    },
    // {
    //   id: 10,
    //   icon: {
    //     title: 'tongzhi',
    //     svg: '<path d="M512 960c-76.8 0-140.8-51.2-160-128H192c-12.8 0-25.6-6.4-25.6-19.2-6.4-12.8-6.4-25.6 0-32l57.6-76.8V416c0-147.2 108.8-268.8 256-288V64h64v64c147.2 19.2 256 140.8 256 288V704l57.6 76.8c6.4 12.8 6.4 25.6 0 32 0 12.8-12.8 19.2-25.6 19.2h-160c-19.2 76.8-83.2 128-160 128z m-89.6-128c19.2 51.2 70.4 76.8 121.6 57.6 25.6-12.8 51.2-32 57.6-57.6H422.4zM512 192C390.4 192 288 294.4 288 416v307.2L256 768h512l-32-44.8V416C736 294.4 633.6 192 512 192z" p-id="6373"></path>'
    //   },
    //   title: '消息',
    //   path: '/information',
    //   warn: true,
    //   isShow: true,
    //   children: [],
    // },
  ]

  constructor() {
    makeAutoObservable(this)
  }

  // 这里还要继续操作session部分

}
export { RouteTable }