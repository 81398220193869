import React from "react";
import { NavigationStore } from "./navigation.store";
import { Global } from './global.store'
import { RouteTable } from './routeList.store'

class RootStore {
  constructor() {
    this.navigationStore = new NavigationStore()
    this.globalStore = new Global()
    this.routeStore = new RouteTable()
  }
}
const rootStore = new RootStore()
const context = React.createContext(rootStore)
const useStore = () => React.useContext(context)
export { useStore }