import moment from 'moment';//时间

/* 中国标准时间 转 正常时间 */
export const FORMATTIME = (val) => {
  return val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : ''
}

/* 中国标准时间/毫秒值 转 正常时间 带时分秒 */
export const TIMEFORMATmin = (msec) => {
  let datetime = new Date(msec);
  // console.log(' 自定义时间转换 ', datetime)
  let year = datetime.getFullYear();
  let month = datetime.getMonth();
  let date = datetime.getDate();
  let hour = datetime.getHours();
  let minute = datetime.getMinutes();
  let second = datetime.getSeconds();

  let result1 = year +
    '-' +
    ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
    '-' +
    ((date + 1) < 10 ? '0' + date : date) +
    ' ' +
    ((hour + 1) < 10 ? '0' + hour : hour) +
    ':' +
    ((minute + 1) < 10 ? '0' + minute : minute) +
    ':' +
    ((second + 1) < 10 ? '0' + second : second);


  return result1;
}
/* 中国标准时间/毫秒值 转 正常时间 不带时分秒 */
export const TIMEFORMAT = (msec) => {
  let datetime = new Date(msec);
  // console.log(' 自定义时间转换 ', datetime)
  let year = datetime.getFullYear();
  let month = datetime.getMonth();
  let date = datetime.getDate();

  let result2 = year +
    '-' +
    ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
    '-' +
    ((date + 1) < 10 ? '0' + date : date);

  return result2;
}

export const SD = (val) => {
  return (val ?? '') !== '' ? val : '--';
}

export const ND = (val) => {
  return (val ?? '') !== '' ? val : '--';
}



export const handleHoverImg = () => {
  var imageSrc = document.querySelector('.hoverImg img');
  if (imageSrc && imageSrc.src) {
    imageSrc.src = handleSrcContent(imageSrc.src)
  }
  return imageSrc
}

export const handleZoomImg = () => {
  var imageSrc = document.querySelector('.zoomImg');
  if (imageSrc && imageSrc.src) {
    imageSrc.src = handleSrcContent(imageSrc.src)
  }

  return imageSrc
}


export const handleSetHtml = (value, flag) => {
  console.log('处理富文本数据---->', value, flag)
  // 正则表达式匹配所有的img标签
  let imgRegex = /<img[^>]+src="([^">]+)"/g;
  let imageRegex = /<image[^>]+src="([^">]+)"/g;

  // 使用正则表达式找到所有的图片链接
  let matchs = [];

  while ((matchs = imgRegex.exec(value)) !== null) {
    let newSrc = ''
    console.log('matchs[1]----->', matchs[1])

    if (flag == 'edit') {
      // 详情的时候使用
      newSrc = handleSrcContent(matchs[1]);
    } else {
      // 提交的时候使用
      newSrc = handleDelContent(matchs[1]);
    }

    value = value.replace(matchs[1], newSrc);

  }
  while ((matchs = imageRegex.exec(value)) !== null) {
    let newSrc = ''

    if (flag == 'edit') {
      // 详情的时候使用
      newSrc = handleSrcContent(matchs[1]);
    } else {
      // 提交的时候使用
      newSrc = handleDelContent(matchs[1]);
    }

    value = value.replace(matchs[1], newSrc);
  }
  console.log('处理数据--->', value);
  return value
}


export const handleDelContent = (url) => {
  let urlArr = []
  let urls = []
  let typeofUrl = 'string'


  if (url) {
    if (typeof url === 'string') {
      typeofUrl = 'string'
      // 处理原始数据----->
      urlArr = url.split(',')
    } else {
      typeofUrl = 'array'
      urlArr = url
    }
    urlArr.map(item => {
      if (item.indexOf('requestId=') != -1 && (item.indexOf('file.marathonbm.com') != -1 || item.indexOf("marathon-image.hpaopao.com") != -1 || item.indexOf("static.hpaopao.com") != -1 || item.indexOf("marathon-web.hpaopao.com") != -1 || item.indexOf("paopao-image.hpaopao.com") != -1 || item.indexOf("psq-img.hpaopao.com") != -1)) {
        if (item.indexOf('?') !== -1) {
          // 有问号
          urls.push(`${item.split('?requestId=')[0]}`)
        } else {
          // 没有问号
          urls.push(`${item.split('&requestId=')[0]}`)
        }
      } else {
        urls.push(item)
      }
    })
  }

  if (typeofUrl == 'string') {
    return urls.toString()
  } else {
    return urls
  }
}

export const handleSrcContent = (url) => {
  let urlArr = []
  let urls = []
  let typeofUrl = 'string'


  if (url) {

    let array = ['982285707324166266', '982285927139250176', '982285927139250181', '982285927139250186', '982285927139250178']
    var randomIndex = Math.floor(Math.random() * array.length);
    var srcNumber = array[randomIndex];

    if (typeof url === 'string') {
      typeofUrl = 'string'
      // 处理原始数据----->
      urlArr = url.split(',')
    } else {
      typeofUrl = 'array'
      urlArr = url
    }


    urlArr.map(item => {
      if (item.indexOf('requestId=') == -1 && (item.indexOf('file.marathonbm.com') != -1 || item.indexOf("marathon-image.hpaopao.com") != -1 || item.indexOf("static.hpaopao.com") != -1 || item.indexOf("marathon-web.hpaopao.com") != -1 || item.indexOf("paopao-image.hpaopao.com") != -1 || item.indexOf("psq-img.hpaopao.com") != -1)) {
        if (item.indexOf('?') !== -1) {
          urls.push(`${item}&requestId=${srcNumber}`)
        } else {
          urls.push(`${item}?requestId=${srcNumber}`)
        }
      } else {
        urls.push(item)
      }
    })
  }

  if (typeofUrl == 'string') {
    return urls.toString()
  } else {
    return urls
  }
}

